import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { MotionButton } from "shadcn/ui/animatedButton";
import { Button } from "shadcn/ui/button";
import { CocktailSearchHeader } from "~/components/CocktailSearchHeader";
import { MainNav } from "~/components/MainNav";
import {
  TypographyH1,
  TypographyH2,
  TypographyLarge,
} from "~/components/Typography.tsx/Typography";
import { CANNY_URL } from "~/constants/CANNY_URL";
import { Instagram } from "lucide-react";
import Link from "next/link";

export default function Home() {
  const router = useRouter();
  const goToCocktailQA = () => {
    router.push("/qa/baseAlcohol");
  };
  useEffect(() => {
    router.prefetch("/qa/baseAlcohol");
  }, []);

  const onCannyLinkClick = () => {
    window.open(CANNY_URL, "_blank");
  };

  return (
    <>
      <Head>
        <title>ShakenAI</title>
        <meta
          name="description"
          content="Shaken. Your AI Cocktail companion & guide"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>{" "}
      <main className="bg-black">
        <div className="p-2P fixed top-0 z-10 w-full  bg-black bg-opacity-90 backdrop-blur-sm backdrop-filter">
          <div className="flex h-16 items-center justify-between px-4">
            <TypographyH1 text="SHAKEN" />
            <MainNav className="mx-6" />
            <div className="flex items-center space-x-4">
              <CocktailSearchHeader />
              {/* <UserNav /> */}
            </div>
          </div>
        </div>
        <div className="flex min-h-screen flex-col items-center justify-center ">
          <div className="container flex flex-col items-center justify-center gap-12 px-4 py-16 ">
            <div className="flex flex-col items-center justify-center gap-8">
              <TypographyH1 text={`Shaken AI 🍸`} className="text-center" />
              <TypographyH2
                text={"Your Cocktail Companion"}
                className="text-center"
              />
            </div>
            <TypographyLarge
              className="max-w-[800px] text-center"
              text="Shaken is your cocktail companion and guide. We help build the perfect cocktail for you, and help you create it at home or find the perfect bar to drink it at."
            />
            <div className="flex flex-row items-center justify-center gap-8">
              <MotionButton onClick={goToCocktailQA} size={"lg"}>
                {"Create your ideal Cocktail "}
              </MotionButton>
              {/* <CocktailSearchHeader /> */}
            </div>
          </div>
        </div>
        <div className="fixed bottom-7 left-4 z-20">
          <Link href="https://www.instagram.com/shaken.ai/" target="_blank">
            <Instagram size={32} />
          </Link>
        </div>
        <div className="fixed bottom-4 flex w-full items-center justify-center rounded-full p-2">
          <Button variant={"link"} onClick={onCannyLinkClick}
          className="max-w-[80%] text-center"
          >
            {`Got some cool ideas you'd like to see in Shaken? Let us know!`}
          </Button>
        </div>
      </main>
    </>
  );
}
