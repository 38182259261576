import { useEffect, useRef } from 'react';

type CallbackFunction = (...args: any[]) => any;

function useDebounceCallback(callback: CallbackFunction, delay: number) {
  // Store the callback function in a mutable ref so it can persist over rerenders
  const callbackRef = useRef<CallbackFunction>(callback);

  // Update the callback ref if it changes
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Initialize the timer ref
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedCallback = (...args: any[]) => {
    // Cancel any existing timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set up a new timer with the specified delay
    timerRef.current = setTimeout(() => {
      // Call the actual callback
      callbackRef.current(...args);
    }, delay);
  };

  return debouncedCallback;
}

export default useDebounceCallback;
