import Link from "next/link";
import { cn } from "shadcn/utils";

// TODO RoutesEnum to ensure this is all correct
export function MainNav({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  return (
    <nav
      className={cn("flex items-center space-x-4 lg:space-x-6", className)}
      {...props}
    >
      {/* <Link
        href="/"
        className="hidden text-sm font-medium transition-colors hover:text-primary md:inline-block"
      >
        Home
      </Link>
      <Link
        href="/qa/baseAlcohol"
        className="hidden text-sm font-medium text-muted-foreground transition-colors hover:text-primary md:inline-block"
      >
        Create a Cocktail
  </Link> */}

      {/* <Link
        href="/settings"
        className="text-sm font-medium text-muted-foreground transition-colors hover:text-primary"
      >
        Settings
  </Link> */}
    </nav>
  );
}
