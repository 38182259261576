import React, { useState } from "react";
import { type z } from "zod";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../shadcn/ui/command";
import { createApiClient, type schemas } from "zod-api/client";
import { API_ENDPOINT } from "~/constants/ENDPOINT";
import { toTitleCase } from "~/utils/stringManipulation";
import useDebounceCallback from "~/hooks/useDebounceCallback";
import { MotionButton } from "shadcn/ui/animatedButton";
import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import { type Cocktail } from "~/@types/Cocktail";
// TODO IMPLEMENT THIS
import { MeiliSearch } from "meilisearch";

// Constants for cocktail categories
const USER_GENERATED = "User Generated Cocktails";
const CLASSIC_COCKTAILS = "Classic Cocktails";
const COCKTAILS_FROM_BARS = "Cocktails from Bars";

const ROUTES: Record<string, string> = {
  [USER_GENERATED]: "/cocktail/new/",
  [COCKTAILS_FROM_BARS]: "/cocktail/bar/",
  [CLASSIC_COCKTAILS]: "/cocktail/",
};

type CocktailResultsSchema = z.infer<typeof schemas.PaginatedCocktailList>;
type Cocktails = CocktailResultsSchema["results"];
//
// Disabled the response validation as there appears to be some drift between the API and the schema.
const apiClient = createApiClient(API_ENDPOINT, { validate: "request" });

/**
 * Categorizes cocktails into three categories:
 * 1. Belongs to a Bar
 * 2. User Generated
 * 3. Not Linked to either Bar or User
 *
 * @param cocktails - Array of cocktail objects to be categorized.
 * @returns An object containing counts and arrays for each category.
 */
function categorizeCocktails(cocktails: Cocktail[]) {
  // Initialize count variables
  let barCocktailCount = 0;
  let userGeneratedCocktailCount = 0;
  let notLinkedCocktailCount = 0;

  // Initialize arrays to store categorized cocktails
  const barCocktails: Cocktail[] = [];
  const userGeneratedCocktails: Cocktail[] = [];
  const nonLinkedCocktails: Cocktail[] = [];

  // Loop through each cocktail to categorize it
  cocktails.forEach((cocktail) => {
    if (cocktail.bar && !cocktail.user) {
      barCocktailCount++;
      barCocktails.push(cocktail);
    } else if (cocktail.user_response && !cocktail.bar && !cocktail.user) {
      userGeneratedCocktailCount++;
      userGeneratedCocktails.push(cocktail);
    } else if (!cocktail.bar && !cocktail.user && !cocktail.user_response) {
      notLinkedCocktailCount++;
      nonLinkedCocktails.push(cocktail);
    }
  });

  // Return the counts and categorized cocktails
  return {
    barCocktailCount,
    userGeneratedCocktailCount,
    notLinkedCocktailCount,
    barCocktails,
    userGeneratedCocktails,
    nonLinkedCocktails,
  };
}

const useSearchCocktails = () => {
  const [state, setState] = useState({
    results: [] as Cocktail[],
    loading: false,
    error: null as string | null,
    searchValue: "",
  });

  const debouncedSearch = useDebounceCallback(async (query: string) => {
    try {
      const result = await apiClient.search_cocktails_list({
        queries: { query },
      });
      setState((prev) => ({
        ...prev,
        results: result?.results || [],
        loading: false,
      }));
    } catch (err) {
      Sentry.captureException(err);
      setState((prev) => ({
        ...prev,
        error: "Failed to fetch results. Please try again.",
        loading: false,
      }));
    }
  }, 200);

  const handleInputChange = (searchValue: string) => {
    setState((prev) => ({ ...prev, searchValue }));
    if (searchValue.length >= 3) {
      setState((prev) => ({ ...prev, loading: true }));
      debouncedSearch(searchValue);
    }
  };

  return { ...state, handleInputChange };
};

const renderLoading = () => <CommandEmpty>Loading...</CommandEmpty>;

const renderError = (error: string) => <CommandEmpty>{error}</CommandEmpty>;

const renderEmpty = () => <CommandEmpty>No results found.</CommandEmpty>;

const renderResults = (
  categories: Array<{ category: string; cocktails: Cocktail[] }>,
  onCocktailSelect: Function
) => {
  return categories.map(({ category, cocktails }) =>
    cocktails.length > 0 ? (
      <CommandGroup key={category} heading={category}>
        {cocktails.map((cocktail) => (
          <CommandItem
            key={cocktail.id}
            onSelect={() => onCocktailSelect(cocktail.id, category)}
          >
            {toTitleCase(cocktail.name)}
          </CommandItem>
        ))}
      </CommandGroup>
    ) : null
  );
};

/**
 * Component to search for cocktails.
 */
const CocktailSearchHeader: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { results, handleInputChange, loading, error, searchValue } =
    useSearchCocktails();
  console.log({ results });
  const { userGeneratedCocktails, nonLinkedCocktails, barCocktails } =
    categorizeCocktails(results);

  console.log({ userGeneratedCocktails, nonLinkedCocktails, barCocktails });
  const router = useRouter();

  const onCocktailSelect = (id: string, category: string) => {
    const route = ROUTES[category] || ROUTES[CLASSIC_COCKTAILS];
    router.push(`${route}${id}`);
  };

  const isEmpty =
    userGeneratedCocktails.length === 0 &&
    nonLinkedCocktails.length === 0 &&
    barCocktails.length === 0;

  return (
    <>
      {/*<MotionButton
        onClick={() => setOpen(!open)}
        size="lg"
        aria-label="Open cocktail search"
      >
        Search for a cocktail 🔍
      </MotionButton> 
  */}
      <CommandDialog open={open} onOpenChange={() => setOpen(!open)}>
        <CommandInput
          placeholder="Enter cocktail name..."
          value={searchValue}
          onValueChange={handleInputChange}
          aria-label="Cocktail search field"
        />
        <CommandList>
          {loading ? (
            <CommandEmpty>Loading...</CommandEmpty>
          ) : isEmpty ? (
            <CommandEmpty>No results found.</CommandEmpty>
          ) : (
            [
              {
                category: "User Generated Cocktails",
                cocktails: userGeneratedCocktails,
              },
              { category: "Classic Cocktails", cocktails: nonLinkedCocktails }, // Pretty sure this needs the classic endpoint
              // TODO: Have a big think about this one. Seems kinda fucked not sure if this is a nick issue
              // { category: "Cocktails from Bars", cocktails: barCocktails }, // Note: This just seems straight fucked
            ].map(({ category, cocktails }) =>
              cocktails.length > 0 ? (
                <CommandGroup key={category} heading={category}>
                  {cocktails.map((cocktail) => (
                    <CommandItem
                      key={cocktail.id}
                      onSelect={() => onCocktailSelect(cocktail.id, category)}
                    >
                      {toTitleCase(cocktail.name)}
                    </CommandItem>
                  ))}
                </CommandGroup>
              ) : null
            )
          )}
          {error && <CommandEmpty>{error}</CommandEmpty>}
        </CommandList>
      </CommandDialog>
    </>
  );
};

export { CocktailSearchHeader };
